import { FetchError } from 'ofetch'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { isAuthenticated } = useSanctumAuth()
  const client = useSanctumClient()
  const config = useSanctumConfig()
  const toastsStore = useToastsStore()

  if (isAuthenticated.value) {
    try {
      await client(config.endpoints.user)
    }
    catch (error) {
      if (error instanceof FetchError && error.response?.status === 401) {
        await useFetch(config.endpoints.csrf, {
          baseURL: config.baseUrl,
          credentials: 'include',
        })

        toastsStore.add('logged_out')
      }
    }
  }
})
