export default defineNuxtRouteMiddleware(async (to, from) => {
  const config = useSanctumConfig()
  try {
    await useFetch('/api/organizations/' + to.params.slug, {
      baseURL: config.baseUrl,
    })
  }
  catch {
    return abortNavigation()
  }
})
