// /store/user.js

import { defineStore } from 'pinia'
import _, { add } from 'lodash'

export const useUserStore = defineStore('user', () => {
  const { isAuthenticated, login, logout, refreshIdentity } = useSanctumAuth()
  const client = useSanctumClient()
  const user = useSanctumUser()
  const localePath = useLocalePath()
  const url = useRequestURL()

  // Properties

  const email = computed(() => user.value.email)
  const uuid = computed(() => user.value.uuid)
  const language = computed(() => user.value.language)
  const individualSubscription = computed(() => user.value.latest_individual_subscription)
  const organizationSubscription = computed(() => user.value.latest_organization_subscription)
  const organizationSubscriptions = computed(() => user.value.organization_subscriptions)
  const userType = computed(() => user.value.user_type.name)
  const canView = computed(() => user.value.can_view)
  const isTeamMember = computed(() => user.value.is_team_member)
  const isSubscribed = computed(() => user.value.is_subscribed)
  const hasValidIndividualSubscription = computed(() => user.value.has_valid_individual_subscription)
  const hasValidOrganizationSubscription = computed(() => user.value.has_valid_organization_subscription)
  const hasCanceledIndividualSubscription = computed(() => user.value.has_canceled_individual_subscription)
  const hasSubscriptions = computed(() => user.value.has_subscriptions)
  const alert = computed(() => user.value.alert)

  // Methods - Auth

  async function register(registerInput: RegisterInput) {
    const localePath = useLocalePath()
    const url = useRequestURL()
    const { gtag } = useGtag()

    return await client('/register', {
      method: 'POST',
      body: {
        success_url: url.origin + localePath('/'),
        cancel_url: url.origin + localePath('/'),
        ...registerInput,
      },
      async onResponse({ response }) {
        const { finish } = useLoadingIndicator()
        finish()

        gtag('event', 'sign_up')
        gtag('set', {
          user_id: uuid,
          user_properties: {
            user_type: userType,
          },
        })

        if (response.status === 201 && !response.headers.get('location')) {
          await refreshIdentity()
          await navigateTo(localePath('/'))
        }
        else if (response.status === 201 && response.headers.get('location')) {
          await navigateTo(response.headers.get('location'), { external: true })
        }
      },
    })
  }

  async function forget(email: string) {
    return await client('/forgot-password', { method: 'POST', body: { email } })
  }

  async function reset(resetInput: ResetInput) {
    return await client('/reset-password', { method: 'POST', body: resetInput })
  }

  // Methods - Account

  async function changePassword(passwordUpdateInput: PasswordUpdateInput) {
    return await client('/user/password', { method: 'PUT', body: passwordUpdateInput })
  }

  async function changeLanguage(language: string) {
    return await client('/user/change-language', { method: 'PATCH', body: { language },
      async onResponse() {
        const { finish } = useLoadingIndicator()
        finish()
        await refreshIdentity()
      } })
  }

  async function changeEmail(email: string) {
    return await client('/user/change-email', { method: 'PATCH', body: { email },
      async onResponse() {
        const { finish } = useLoadingIndicator()
        finish()
        await refreshIdentity()
      } })
  }

  async function createCheckoutSession(successUrl = url.origin + localePath('/'), cancelUrl = url.origin + localePath('/')) {
    return await client('/user/subscription-checkout', {
      method: 'POST',
      body: {
        success_url: successUrl,
        cancel_url: cancelUrl,
      },
      async onResponse({ response }) {
        const { finish } = useLoadingIndicator()
        finish()
        if (response.status === 201) {
          await navigateTo(response.headers.get('location'), { external: true })
        }
      },
    })
  }

  async function createPortalSession(returnUrl = url.origin + localePath('auth-account')) {
    return await client('/user/billing-portal', {
      method: 'POST',
      body: {
        return_url: returnUrl,
      },
      async onResponse({ response }) {
        const { finish } = useLoadingIndicator()
        finish()
        if (response.status === 201) {
          await navigateTo(response.headers.get('location'), { external: true })
        }
      },
    })
  }

  async function cancelSubscription(returnUrl = url.origin + localePath('auth-account')) {
    return await client('/user/billing-portal/cancel-subscription', {
      method: 'POST',
      body: {
        return_url: returnUrl,
      },
      async onResponse({ response }) {
        const { finish } = useLoadingIndicator()
        finish()
        if (response.status === 201) {
          await navigateTo(response.headers.get('location'), { external: true })
        }
      },
    })
  }

  async function updatePaymentMethod(returnUrl = url.origin + localePath('auth-account')) {
    return await client('/user/billing-portal/update-payment-method', {
      method: 'POST',
      body: {
        return_url: returnUrl,
      },
      async onResponse({ response }) {
        const { finish } = useLoadingIndicator()
        finish()
        if (response.status === 201) {
          await navigateTo(response.headers.get('location'), { external: true })
        }
      },
    })
  }

  async function deleteAccount() {
    return await client('/user/delete-account', {
      method: 'DELETE',
      async onResponse({ response }) {
        const { finish } = useLoadingIndicator()
        const localePath = useLocalePath()
        finish()
        if (response.status === 204) {
          user.value = null
          await navigateTo(localePath('/'))
        }
      },
    })
  }

  async function addOrganizationSubscription(addOrganizationSubscriptionInput: AddOrganizationSubscriptionInput) {
    return await client('/user/add-organization-subscription', {
      method: 'POST',
      body: addOrganizationSubscriptionInput,
      async onResponse({ response }) {
        const { finish } = useLoadingIndicator()
        finish()
        if (response.status === 201) {
          await navigateTo('/')
        }
      },
    })
  }

  return {
    email,
    language,
    userType,
    uuid,
    individualSubscription,
    organizationSubscription,
    organizationSubscriptions,
    isAuthenticated,
    canView,
    isSubscribed,
    isTeamMember,
    hasSubscriptions,
    hasValidIndividualSubscription,
    hasValidOrganizationSubscription,
    hasCanceledIndividualSubscription,
    alert,
    login,
    register,
    forget,
    logout,
    reset,
    changePassword,
    changeLanguage,
    changeEmail,
    createCheckoutSession,
    createPortalSession,
    cancelSubscription,
    updatePaymentMethod,
    deleteAccount,
    addOrganizationSubscription,
  }
})
