export const usePaywallStore = defineStore('paywall', () => {
  const userStore = useUserStore()
  const whitelisted = ref(false)

  const hasAccess = computed(() => {
    if (userStore.isAuthenticated && userStore.canView) {
      return true
    }
    else {
      return whitelisted.value
    }
  })

  async function initialize() {
    try {
      const isAllowed = await useFetch('/api/ip-check')
      whitelisted.value = isAllowed
    }
    catch (err) {
      console.log('Failed to check IP:', err)
    }
  }

  return {
    hasAccess,
    initialize,
  }
})
