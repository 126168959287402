import { from } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { parseSetCookie, serialize } from 'cookie-es'

export default defineNuxtPlugin(async (nuxtApp) => {
  const { $apollo } = useNuxtApp()
  const config = useSanctumConfig()

  const authLink = setContext(async (request, previousContext) => {
    const headers = {}
    if (import.meta.server) {
      const res = await $fetch.raw(config.endpoints.csrf, {
        baseURL: config.baseUrl,
      })
      /* Get the cookies from the response */
      const setCookies = res.headers.getSetCookie()
      /* Parse the cookies */
      const cookies = []
      for (const setCookie of setCookies) {
        cookies.push(parseSetCookie(setCookie))
      }

      const cookieHeader = cookies.map(cookie => serialize(cookie.name, cookie.value)).join('; ')
      const csrfCookie = cookies.find(cookie => cookie.name === config.csrf.cookie).value

      headers['Cookie'] = cookieHeader
      headers[config.csrf.header] = csrfCookie
    }

    if (import.meta.client) {
      // Update CSRF token
      headers[config.csrf.header] = useCookie(config.csrf.cookie, { readonly: true }).value
    }
    return { headers }
  })

  // Add the auth link to the default link

  $apollo.defaultClient.setLink(
    from([
      authLink,
      $apollo.defaultClient.link,
    ]),
  )
})
